// react
import React, { useState, Fragment } from "react";
import Link from "@material-ui/core/Link";
import { Link as SLink } from "react-scroll";
// mui
import { makeStyles } from "@material-ui/core/styles";
// mui component
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import AppBar from "@material-ui/core/AppBar";
import ToolBar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
// mui peripheral
// components
import HeaderDrawer from "./HeaderDrawer";
import HeaderIconsD from "./HeaderIconsD";
import HeaderIconsM from "./HeaderIconsM";
// others
import { resolveLogoURL } from "../utils";
import { useDisplaySize } from "../hooks";

const useStyles = makeStyles((theme) => ({
  rootM: {},
  appbar: {
    boxSizing: "border-box",
    boxShadow: "none",
    margin: 0,
    backgroundColor: "transparent",
  },
  headerMenus: {
    position: "fixed",
    right: 0,
    top: 0,
    zIndex: 1300,
    width: "56px",
    height: "56px",
    background: "#CCCCCC 0% 0% no-repeat padding-box",
    boxShadow: "0px 3px 6px #00000029", // とりあえず直で使用する
  },
  iconButton: {
    width: "56px",
    height: "56px",
  },
  // ヘッダーのボタンのアイコン
  headerMenusIcon: {
    width: "24px",
    height: "24px",
    opacity: 1,
  },
  scrollAnchor: {
    display: "hidden",
  },
  title: {
    flexGrow: 1,
  },
  appbarD: {
    height: "64px",
    // backgroundColor: 'rgba( 255, 255, 255, 0.12 )',
    color: "#fefefe",
    boxShadow: "none",
  },
  headerMenuD: {
    marginRight: theme.spacing(2),
    fontWeight: 600,
  },
  headerItemD: {
    backgroundColor: "rgba( 255, 255, 255, 0.12 )",
    fontSize: "1rem",
    fontWeight: 600,
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    color: "#000",
    "&:hover, &:focus": {
      color: "#fefefe",
      background: "rgba(0,0,0,.87)",
    },
  },
  logoD: {
    position: "fixed",
    top: -40,
    left: -20,
    cursor: "pointer",
    fill: "#fefefe",
    mixBlendMode: "difference",
  },
  logo: {
    position: "fixed",
    top: -30,
    left: -30,
  },
}));

const Header = () => {
  const c = useStyles();
  const { desktop } = useDisplaySize();

  const [state, setState] = useState({
    top: false,
  });
  const toggleDrawer = (side, open) => (e) => {
    // e.preventDefault();
    setState({ ...state, [side]: open });
  };

  const HeaderItemD = ({ to, offset, duration = 1500, text }) => (
    <SLink
      activeClass="active"
      to={to}
      spy={true}
      smooth="easeInOutQuint"
      offset={offset}
      duration={duration}
    >
      <Button className={c.headerItemD}>{text}</Button>
    </SLink>
  );

  return desktop ? (
    <Fragment>
      <AppBar position="fixed" color="transparent" className={c.appbarD}>
        <ToolBar>
          <Typography variant="h6" className={c.title}>
            {/* TBU: ロゴ */}
            <SLink
              activeClass="active"
              to="top"
              spy={true}
              smooth="easeInOutQuint"
              offset={-20}
              duration={1500}
            >
              <img
                className={c.logoD}
                src={resolveLogoURL()}
                alt="johnnivan-logo"
                width="400"
                height="200"
              />
            </SLink>
          </Typography>
          <div className={c.headerMenuD}>
            <HeaderItemD
              to="newRelease"
              offset={0}
              text="White Bicycle, Lightweight - NEW SINGLE"
            />
            <HeaderItemD to="lives" offset={0} text="LIVE" />
            <HeaderItemD to="discography" offset={0} text="DISCOGRAPHY" />
            {/* SHOP有効化時にuncomment */}
            <Link
              target="_blank"
              href={process.env.REACT_APP_SHOP_URL}
              style={{ textDecoration: "none" }}
            >
              <Button className={c.headerItemD}>SHOP</Button>
            </Link>
            <HeaderItemD to="photos" offset={-80} text="PHOTO" />
            <HeaderItemD to="footer" offset={0} text="CONTACT US" />
          </div>
        </ToolBar>
      </AppBar>
      <HeaderIconsD />
    </Fragment>
  ) : (
    <div className={c.rootM}>
      <AppBar position="fixed" color="transparent" className={c.appbar}>
        <ToolBar disableGutters>
          <Typography variant="h6" className={c.title}>
            {/* TBU: ロゴ */}
            <SLink
              activeClass="active"
              to="top"
              spy={true}
              smooth="easeInOutQuint"
              offset={-100}
              duration={1500}
            >
              <img
                className={c.logo}
                src={resolveLogoURL()}
                alt="バンドロゴ"
                width="250"
                height="150"
              />
            </SLink>
          </Typography>
          <div className={c.headerMenus}>
            <IconButton
              className={c.iconButton}
              aria-label="HeaderMenu"
              onClick={toggleDrawer("top", true)}
            >
              <MenuIcon className={c.headerMenusIcon} />
            </IconButton>
            <HeaderDrawer toggleDrawer={toggleDrawer} state={state} />
          </div>
        </ToolBar>
      </AppBar>
      <div id="mainContent" className={c.scrollAnchor} />
      <HeaderIconsM />
    </div>
  );
};

export default Header;
